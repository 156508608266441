// eslint-disable-next-line no-restricted-imports
import { useRouter } from 'next/router';
import { QueryString, toQueryParams } from '@utils/routerUtils';
import { Route } from '@web/types/enums';
import { useConstants } from '../ConstantContext';

const usePartnerRouter = () => {
  const { partner } = useConstants();
  const {
    asPath,
    back,
    beforePopState,
    events,
    isReady,
    pathname: currentPathname,
    push,
    query: { partner: _, ...currentQuery },
    reload,
    replace,
    route,
  } = useRouter();

  return {
    push: ({
      keepPreviousQuery = true,
      pathname,
      query = {},
      shallow,
    }: {
      keepPreviousQuery?: boolean;
      pathname?: string;
      query?: QueryString;
      shallow?: boolean;
    }) => {
      const newPath = (pathname || currentPathname).replace('/[partner]', '');
      const newQuery = {
        ...(keepPreviousQuery ? currentQuery : {}),
        ...query,
        ...(shallow ? { partner } : {}),
      };

      return push(
        {
          pathname: `/[partner]${newPath}`,
          query: newQuery,
        },
        `${newPath}${toQueryParams(newQuery)}`,
        {
          shallow,
        },
      );
    },
    replace: ({
      keepPreviousQuery = true,
      pathname,
      query,
      shallow,
    }: {
      keepPreviousQuery?: boolean;
      pathname?: string;
      query?: QueryString;
      shallow: boolean;
    }) => {
      const newPath = (pathname || currentPathname).replace('/[partner]', '');
      const newQuery = {
        ...(keepPreviousQuery ? currentQuery : {}),
        ...query,
        ...(shallow ? { partner } : {}),
      };

      replace(
        {
          pathname: `/[partner]${newPath}`,
          query: newQuery,
        },
        `${newPath}${toQueryParams(newQuery)}`,
        {
          shallow,
        },
      );
    },
    getPartnerHref: ({
      keepPreviousQuery = true,
      pathname,
      query = {},
    }: {
      keepPreviousQuery?: boolean;
      pathname?: string;
      query?: QueryString;
    }) => {
      const path = pathname || currentPathname;
      const newQuery = {
        ...(keepPreviousQuery ? currentQuery : {}),
        ...query,
        partner,
      };

      return `${path}${toQueryParams(newQuery)}`;
    },
    asPath: asPath.replace('/[partner]', ''),
    query: currentQuery,
    events,
    beforePopState,
    route: (route === '/[partner]'
      ? '/'
      : route.replace('/[partner]', '')) as Route,
    back,
    reload,
    isReady,
  };
};

export default usePartnerRouter;
