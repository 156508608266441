import styled from '@emotion/styled';
import Box from '@ui-v2/core/Box/Box';
import usePartnerRouter from '@web/context/hooks/usePartnerRouter';
import { isBookingRoute } from '@web/utils/booking/bookingUtils';
import Footer from './Footer/Footer';
import HeaderContainer from './HeaderContainer';
import MaintainceBannerContainer from './MaintainceBannerContainer';

const StyledLayoutGrid = styled.div`
  display: grid;
  min-height: 100vh;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
`;

const PageLayout = ({ children }: { children: React.ReactNode }) => {
  const { route } = usePartnerRouter();

  return (
    <StyledLayoutGrid>
      <div>
        <MaintainceBannerContainer />
        <HeaderContainer />
      </div>
      <Box bg="background.main" position="relative">
        {children}
      </Box>
      <Footer />
      {/* Push footer up when the fixed booking footer is active */}
      {isBookingRoute(route) && <Box py={[40, 40, 40, 40, 0]} />}
    </StyledLayoutGrid>
  );
};

export default PageLayout;
