import { AriaAttributes, HTMLAttributeAnchorTarget } from 'react';
import NextLink, { LinkProps } from 'next/link';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { createTypography, shouldForwardProp } from '../../styles/base';

export type LinkType = 'primary' | 'secondary' | 'info' | 'text';

export type ColorSaturation = 'light' | 'dark' | 'main';

export interface Props {
  ariaLabel?: AriaAttributes['aria-label'];
  children?: React.ReactNode;
  className?: string;
  colorSaturation?: ColorSaturation;
  href: string | { as: string; href: string };
  legacyBehavior?: LinkProps['legacyBehavior'];
  linkType?: LinkType;
  locale?: LinkProps['locale'];
  shallow?: boolean;
  suppressHydrationWarning?: boolean;
  target?: HTMLAttributeAnchorTarget;
  useFixedTextDecoration?: boolean;
}

export const StyledLink = styled(NextLink, {
  shouldForwardProp: (prop) =>
    shouldForwardProp({
      prop,
      excludeProps: ['useFixedTextDecoration', 'linkType', 'colorSaturation'],
    }),
})<{
  colorSaturation?: ColorSaturation;
  linkType?: LinkType;
  useFixedTextDecoration?: boolean;
}>(({ theme: { colours, typography }, useFixedTextDecoration }) => [
  createTypography(typography.body01),
  css`
    color: ${colours.text.link.default};
    text-decoration: ${useFixedTextDecoration ? 'underline' : 'none'};
    transition: color 250ms;

    &:hover {
      color: ${colours.text.link.hover};
      text-decoration: underline;
    }
  `,
]);

const Link = ({
  ariaLabel,
  children,
  className,
  colorSaturation,
  href,
  legacyBehavior,
  linkType,
  locale,
  shallow,
  suppressHydrationWarning,
  target = '_blank',
  useFixedTextDecoration,
}: Props) => {
  return (
    <StyledLink
      aria-label={ariaLabel}
      className={className}
      colorSaturation={colorSaturation}
      href={href}
      legacyBehavior={legacyBehavior}
      linkType={linkType}
      locale={locale}
      rel={target === '_blank' ? 'noreferrer' : undefined}
      shallow={shallow}
      suppressHydrationWarning={suppressHydrationWarning}
      target={target}
      useFixedTextDecoration={useFixedTextDecoration}
    >
      {children}
    </StyledLink>
  );
};

export default Link;
