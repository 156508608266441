import { useIsMutating } from '@tanstack/react-query';

export enum OfferMutations {
  ALYZIA_SELECT = 'alyzia_select',
  BILLING_ADDRESS = 'billing_address',
  BOOK_OFFER = 'book_offer',
  BOOK_RETRY = 'book_retry',
  BUNDLE_SELECT = 'bundle_select',
  CONTACT_INFO = 'contact_info',
  NUMBER_OF_PASSENGERS = 'number_of_passengers',
  PASSENGER_INFO = 'passenger_info',
  SEAT_SELECTION = 'seat_selection',
  SERVICES = 'services',
}

const useIsUpdating = (mutations: OfferMutations[]) => {
  return (
    useIsMutating({
      predicate: (mutation) => {
        return mutations.includes(
          mutation.options.mutationKey?.[0] as OfferMutations,
        );
      },
    }) > 0
  );
};

export default useIsUpdating;
