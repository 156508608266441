import { useEffect } from 'react';
import NProgress from 'nprogress';
import usePartnerRouter from '../hooks/usePartnerRouter';
import 'nprogress/nprogress.css';

const useRoutingIndicator = () => {
  const router = usePartnerRouter();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleStart = (url: string, params: { shallow: boolean }) => {
      if (!params.shallow) {
        NProgress.start();
      }
    };

    const handleComplete = () => NProgress.done();

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, [router]);
};

export default useRoutingIndicator;
