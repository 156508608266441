import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { StructuredText } from 'datocms-structured-text-utils';
import useTranslation from 'next-translate/useTranslation';
import {
  ImageWithConfigFragment,
  StructuredTextFragment,
} from '@codegen/cmsUtils';
import StructuredTextWrapper from '@ui-v2/components/RichContent/StructuredText';
import Box from '@ui-v2/core/Box/Box';
import Icon, { IconProps } from '@ui-v2/core/Icon/Icon';
import Image from '@ui-v2/core/Image/Image';
import Modal, { ModalProps } from '@ui-v2/core/Modal/Modal';
import OneButtonModalFooter from '@ui-v2/core/Modal/OneButtonModalFooter';
import Text from '@ui-v2/core/Text/Text';
import useGetIllustration from '@web/hooks/useGetIllustration';

type Props = {
  clockIcon: ImageWithConfigFragment | null;
  closeIcon: ImageWithConfigFragment | null;
  isOpen: boolean;
  onOpenChange: ModalProps['onOpenChange'];
  planeIcon: ImageWithConfigFragment | null;
  richModalBulletpoints: StructuredTextFragment[];
  richModalTitle: string;
  trustPilotWidget: React.ReactNode;
};

const StyledContentWrapper = styled.div(
  ({ theme }) => css`
    strong {
      display: inline-block;
      margin-bottom: ${theme.spacings[4]}px;
    }
  `,
);

const DohopConnectRichInformationModal = ({
  clockIcon,
  closeIcon,
  isOpen,
  onOpenChange,
  planeIcon,
  richModalBulletpoints,
  richModalTitle,
  trustPilotWidget,
}: Props) => {
  const { t } = useTranslation();
  const dohopConnectIllustration = useGetIllustration()(
    'dohopConnectIllustration',
  );

  const columnIcons: IconProps['icon'][] = [planeIcon, clockIcon];

  return (
    <Modal
      closeIcon={closeIcon}
      footer={
        <OneButtonModalFooter onOpenChange={onOpenChange} text={t('Close')} />
      }
      id="dohop-connect-rich-information-modal"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      title={richModalTitle}
    >
      <Box
        display="flex"
        flexWrap={['wrap', 'wrap', 'nowrap']}
        gap={32}
        pb={24}
        pl={24}
        pr={[0, 0, 24]}
        pt={16}
      >
        <Box
          display="flex"
          flexShrink={0}
          mx={['auto', 'auto', 0]}
          widthCustom={120}
        >
          {dohopConnectIllustration?.asset.url && (
            <Image
              height={105}
              src={dohopConnectIllustration.asset.url}
              width={120}
            />
          )}
        </Box>
        <Box display="flex" flexWrap={['wrap', 'wrap', 'nowrap']} gap={32}>
          {richModalBulletpoints.map((bullet, index) => (
            <Box
              key={bullet.id}
              position="relative"
              width={['full', 'full', '6/12']}
            >
              <Text as="div">
                <Box
                  display={['block', 'block', 'none', 'block']}
                  float={['none', 'none', 'left']}
                  left={[-28, -28, -8]}
                  position={['absolute', 'absolute', 'relative']}
                  top={2}
                >
                  <Icon icon={columnIcons[index] ?? planeIcon} size={18} />
                </Box>
                <StyledContentWrapper>
                  <StructuredTextWrapper
                    data={bullet.content as StructuredText | null | undefined}
                  />
                </StyledContentWrapper>
              </Text>
            </Box>
          ))}
        </Box>
      </Box>
      <Box display="flex" gap={[0, 0, 32]} pb={8} px={24}>
        <Box widthCustom={[0, 0, 120]}>
          {/* Faux icon box to align content */}
        </Box>
        <Box>
          <Text as="h3" mb={8} variant="heading-5">
            {t('dohop_information_modal_trustpilot_title')}
          </Text>
          <Box height="100px" widthCustom={162}>
            {trustPilotWidget}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default DohopConnectRichInformationModal;
