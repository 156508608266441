import { createContext, ReactNode, useContext } from 'react';
import {
  ExperimentVariantsFragment,
  useGetPartnerExperimentQuery,
} from '@codegen/cmsUtils';
import { Experiment } from '@web/types/experimentTypes';
import {
  getExperimentDataFromCMS,
  getExperimentKeys,
  getExperimentsKeysForRoutes,
} from '@web/utils/experimentUtils';
import { useConstants } from './ConstantContext';
import usePartnerRouter from './hooks/usePartnerRouter';

interface ExperimentData {
  experimentData?: ExperimentVariantsFragment;
  experiments?: Experiment[];
  isBasketLoadingExperiment?: boolean;
  isBestOfferTaggingExperiment?: boolean;
  isMovingPassengerStepExperiment?: boolean;
}

const context = createContext<ExperimentData>({});

const ExperimentProvider = ({ children }: { children: ReactNode }) => {
  const { locale, partner } = useConstants();
  const { route } = usePartnerRouter();

  const experimentKeysFromRoute = getExperimentsKeysForRoutes(route);
  const experimentKeysFromCookies = getExperimentKeys();

  const experiments = [
    // We use cookie experiments to override the route experiments
    ...experimentKeysFromRoute.filter(
      (exp) =>
        !experimentKeysFromCookies.some(
          (cookieExp) => cookieExp.name === exp.name,
        ),
    ),
    ...experimentKeysFromCookies,
  ];

  const shouldRunQuery = experiments.some(
    (experiment) => experiment.variant !== 'current',
  );

  const { data } = useGetPartnerExperimentQuery(
    {
      partner,
      locale,
    },
    {
      enabled: shouldRunQuery,
    },
  );

  const experimentData = getExperimentDataFromCMS({
    partnerExperiment: data?.partner,
    experiments,
  });

  const isMovingPassengerStepExperiment = experiments.some(
    (exp) =>
      exp.name === 'experiment.2023q4-move-pax-step-v4' &&
      exp.variant === 'variantMovePaxStep',
  );

  const isBestOfferTaggingExperiment = experiments.some(
    (exp) =>
      exp.name === 'experiment.2023q4-tag-best-offers' &&
      exp.variant === 'variantTagBestOffers',
  );

  const isBasketLoadingExperiment = experiments.some(
    (exp) =>
      exp.name === 'experiment.2023q4-basket-loading' &&
      exp.variant === 'variantNewBasketLoading',
  );

  return (
    <context.Provider
      value={{
        experiments,
        experimentData,
        isMovingPassengerStepExperiment,
        isBestOfferTaggingExperiment,
        isBasketLoadingExperiment,
      }}
    >
      {children}
    </context.Provider>
  );
};

export default ExperimentProvider;

export const useExperiments = () => useContext(context);
