import { Fragment } from 'react';
import styled from '@emotion/styled';
import { StructuredText } from 'datocms-structured-text-utils';
import useTranslation from 'next-translate/useTranslation';
import {
  ImageWithConfigFragment,
  useStandaloneContentQuery,
} from '@codegen/cmsUtils';
import { Language } from '@shared/types/enums';
import Modal from '@ui-v2/core/Modal/Modal';
import OneButtonModalFooter from '@ui-v2/core/Modal/OneButtonModalFooter';
import { legacySpacings } from '@ui-v2/theme/layout';
import StructuredTextWrapper from '../Content/StructuredTextWrapper';

interface Props {
  closeIcon: ImageWithConfigFragment | null;
  id: string;
  isOpen: boolean;
  locale: Language;
  onCloseModal: () => void;
}

const StyledContent = styled.div`
  p {
    padding-bottom: ${legacySpacings.Medium}px;
  }
`;

const NoCheckedBagsModal = ({ id, isOpen, locale, onCloseModal }: Props) => {
  const { t } = useTranslation();
  const { data } = useStandaloneContentQuery({
    identifier: 'no-checked-bags-journey-info',
    locale,
  });

  return (
    <Modal
      footer={
        <OneButtonModalFooter onOpenChange={onCloseModal} text={t('Close')} />
      }
      id={id}
      isOpen={isOpen}
      onOpenChange={onCloseModal}
    >
      <StyledContent>
        {data?.allStandaloneContents.map((content, index) => (
          <Fragment key={`content-${index.toString()}`}>
            {content.content.map((item) =>
              item.__typename === 'StructuredParagraphRecord' ? (
                <StructuredTextWrapper
                  data={item.content?.content as Maybe<StructuredText>}
                  key={`${item.id}StructuredParagraph`}
                />
              ) : null,
            )}
          </Fragment>
        ))}
      </StyledContent>
    </Modal>
  );
};

export default NoCheckedBagsModal;
