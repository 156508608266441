import useTranslation from 'next-translate/useTranslation';
import { Vendors } from '@codegen/cmsTypes';
import { ImageWithConfigFragment } from '@codegen/cmsUtils';
import { ItineraryFragment } from '@codegen/gatewayUtils';
import { Language } from '@shared/types/enums';
import Box from '@ui-v2/core/Box/Box';
import Button from '@ui-v2/core/Button/Button';
import Modal from '@ui-v2/core/Modal/Modal';
import { offerhasNoCheckedBagsConnection } from '@utils/itineraryUtils';
import NoCheckedBagsBadge from '../Offer/NoCheckedBagsBadge';
import Itinerary from './Itinerary';

export interface Props {
  closeIcon: ImageWithConfigFragment | null;
  dohopConnectName: string;
  informationIcon: ImageWithConfigFragment | null;
  isOpen: boolean;
  itinerary?: ItineraryFragment;
  itineraryPlaneIcon: ImageWithConfigFragment | null;
  language: Language;
  noLuggageIcon: ImageWithConfigFragment | null;
  onNoCheckedBagsClick: () => void;
  residency: string;
  showItineraryVendorLogos: boolean;
  showSelfConnectList: boolean;
  toggleInformationModal: () => void;
  toggleItineraryModal: () => void;
  trainIcon: ImageWithConfigFragment | null;
  vendors?: Vendors;
  warningIcon: ImageWithConfigFragment | null;
}

const ItineraryModal = ({
  closeIcon,
  dohopConnectName,
  informationIcon,
  isOpen,
  itinerary,
  itineraryPlaneIcon,
  language,
  noLuggageIcon,
  onNoCheckedBagsClick,
  residency,
  showItineraryVendorLogos,
  showSelfConnectList,
  toggleInformationModal,
  toggleItineraryModal,
  trainIcon,
  vendors,
  warningIcon,
}: Props) => {
  const { t } = useTranslation();

  const hasNoCheckedBagsConnection = offerhasNoCheckedBagsConnection(itinerary);

  return (
    <Modal
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={false}
      closeIcon={closeIcon}
      footer={
        <Box display="flex" flex={1} justifyContent="space-between">
          {hasNoCheckedBagsConnection && (
            <NoCheckedBagsBadge
              noLuggageIcon={noLuggageIcon}
              onClickBadge={onNoCheckedBagsClick}
            />
          )}
          <Button
            ml="auto"
            onClick={toggleItineraryModal}
            size="small"
            variant="secondary"
          >
            {t('Close')}
          </Button>
        </Box>
      }
      id="basket-itinerary-modal"
      isOpen={isOpen}
      mobileSize="full-screen"
      onOpenChange={toggleItineraryModal}
      title={t('Your itinerary')}
    >
      {itinerary && vendors && (
        <Itinerary
          dohopConnectName={dohopConnectName}
          hasNoCheckedBagsConnection={hasNoCheckedBagsConnection}
          informationIcon={informationIcon}
          itinerary={itinerary}
          itineraryPlaneIcon={itineraryPlaneIcon}
          language={language}
          residency={residency}
          showFareRules={false}
          showItineraryVendorLogos={showItineraryVendorLogos}
          showSelfConnectList={showSelfConnectList}
          showSelfTransfer
          toggleInformationModal={toggleInformationModal}
          trainIcon={trainIcon}
          vendors={vendors}
          warningIcon={warningIcon}
        />
      )}
    </Modal>
  );
};

export default ItineraryModal;
