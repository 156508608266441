import { createContext, ReactNode, useContext, useRef, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import useTranslation from 'next-translate/useTranslation';
import ObjectGeneric from '@shared/types/objectGenericTypes';
import StandaloneContentModal from '@ui/components/Content/StandaloneContentModal';
import { useConstants } from '@web/context/ConstantContext';
import useStandaloneContent from '@web/context/hooks/useStandaloneContent';
import { useSettings } from '@web/context/SettingsContext';
import useGetIcon from '@web/hooks/useGetIcon';

const DEFAULT_ERROR_CONTENT_ID = 'default-error-content';

type ErrorContent = {
  context: ObjectGeneric;
  message: string;
};

export type ErrorOrchestrator = {
  hideError: () => void;
  showError: ({
    action,
    errorContent,
    errorContentId,
  }: {
    action?: () => void;
    errorContent?: ErrorContent;
    errorContentId?: string;
  }) => void;
};

const context = createContext<ErrorOrchestrator>({
  hideError: () => {},
  showError: () => {},
});

export default context;

export const ErrorOrchestratorContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { t } = useTranslation();
  const { locale } = useConstants();
  const { residency } = useSettings();
  const [isErrorShown, toggleIsErrorShown] = useState(false);
  const [contentId, setContentId] = useState<string>(DEFAULT_ERROR_CONTENT_ID);
  const callbackAction = useRef(() => {});
  const { content } = useStandaloneContent(contentId);
  const closeIcon = useGetIcon()('closeIcon');

  const hideError = () => {
    toggleIsErrorShown(false);
    setContentId(DEFAULT_ERROR_CONTENT_ID);
    callbackAction.current();

    callbackAction.current = () => {};
  };

  const showError = ({
    action,
    errorContent,
    errorContentId = DEFAULT_ERROR_CONTENT_ID,
  }: {
    action?: () => void;
    errorContent?: ErrorContent;
    errorContentId?: string;
  }) => {
    toggleIsErrorShown(true);
    setContentId(errorContentId);

    if (errorContent) {
      Sentry.captureMessage(errorContent.message, {
        contexts: {
          info: errorContent.context,
        },
      });
    }

    if (action) {
      callbackAction.current = action;
    }
  };

  return (
    <context.Provider value={{ hideError, showError }}>
      {children}
      {isErrorShown && (
        <StandaloneContentModal
          closeIcon={closeIcon}
          content={content}
          id={contentId}
          isOpen
          locale={locale}
          residency={residency}
          title={t('generic_booking_error_title')}
          toggleModal={hideError}
        />
      )}
    </context.Provider>
  );
};

export const useErrorOrchestrator = () => useContext(context);
