import React, { useState } from 'react';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { legacyZIndices } from '@ui-v2/theme/zIndices';
import { useBaseAnalytics } from '@ui-v2/utils/contexts/BaseAnalyticsContext';

export interface Props {
  children: React.ReactNode | React.ReactNode[];
  id: string;
  text: string | React.ReactNode | React.ReactNode[];
}

const TooltipContainer = styled.div(
  ({ theme: { colours, shape, spacings } }) => css`
    max-width: 350px;
    padding: ${spacings['8']}px;
    border-radius: ${shape.borderRadiusM}px;
    margin-bottom: -1px;
    background: ${colours.brand.primary};
    color: ${colours.text.on.interactive.primary.default};
    text-align: center;
  `,
);

const Tooltip = ({ children, id, text }: Props) => {
  const { colours } = useTheme();
  const [showTooltip, toggleShowTooltip] = useState(false);
  const { sendTooltipShownEvent } = useBaseAnalytics();

  const setShowTooltip = () => {
    toggleShowTooltip(true);
    sendTooltipShownEvent({ id });
  };

  const setHideTooltip = () => toggleShowTooltip(false);

  return (
    <Popover
      containerStyle={{ zIndex: String(legacyZIndices.max) }}
      content={({ childRect, popoverRect, position }) => (
        <ArrowContainer
          aria-hidden={!showTooltip}
          arrowColor={colours.brand.primary}
          arrowSize={10}
          childRect={childRect}
          popoverRect={popoverRect}
          position={position}
        >
          <TooltipContainer id={id}>{text}</TooltipContainer>
        </ArrowContainer>
      )}
      isOpen={showTooltip}
      padding={10}
      positions={['top', 'bottom', 'left', 'right']}
    >
      <span
        aria-describedby={id}
        onBlur={setHideTooltip}
        onClick={(event) => {
          event.stopPropagation();
        }}
        onFocus={setShowTooltip}
        onKeyDown={(event) => {
          event.stopPropagation();
        }}
        onMouseEnter={setShowTooltip}
        onMouseLeave={setHideTooltip}
        role="button"
        tabIndex={-1}
      >
        {children}
      </span>
    </Popover>
  );
};

export default Tooltip;
