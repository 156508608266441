import { css, Theme } from '@emotion/react';
import { createTypography, mqPrint } from './base';

export const getDefaults = ({ colours, typography }: Theme) => css`
  body {
    color: ${colours.text.default};
    ${createTypography(typography.body01)};
  }

  #nprogress .bar {
    background: ${colours.interactive.primary};
  }

  button:active,
  div[role='button']:active,
  input:active {
    outline: none;
  }

  ${mqPrint} {
    @page {
      margin: 0;
    }

    body {
      margin: 0 1.6cm;
    }

    html {
      background: ${colours.background.main};
      filter: blur(0);
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
    }
  }
`;
