import useTranslation from 'next-translate/useTranslation';
import { ImageWithConfigFragment } from '@codegen/cmsUtils';
import Icon from '@ui-v2/core/Icon/Icon';
import Notification from '@ui-v2/core/Notification/Notification';

export interface Props {
  stopDestination?: string;
  stopOrigin: string;
  warningIcon: ImageWithConfigFragment | null;
}

const ItineraryGroundTransitWarning = ({
  stopDestination,
  stopOrigin,
  warningIcon,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Notification variant="success">
      {warningIcon && (
        <Icon colour="icons.on.success" icon={warningIcon} size={20} />
      )}

      {t('ground_transit_info', {
        origin: stopOrigin,
        destination: stopDestination,
      })}
    </Notification>
  );
};

export default ItineraryGroundTransitWarning;
