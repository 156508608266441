import { Vendors } from '@codegen/cmsTypes';
import { RUNTIME_ENV } from '@shared/types/enums';

export const shouldShowSeatmapForVendors = (vendors: Vendors) => {
  return vendors.some(
    (vendor) =>
      vendor.vendorBookingConfig?.seatsConfig &&
      !vendor.vendorBookingConfig.seatsConfig.environmentBlacklist?.includes(
        process.env.NEXT_PUBLIC_RUNTIME_ENV ?? RUNTIME_ENV.dev,
      ),
  );
};
