// eslint-disable-next-line no-restricted-imports
import { useRouter } from 'next/router';
import {
  IconConfigFragment,
  ImageWithConfigFragment,
  useDohopConnectQuery,
  useGetPartnerQuery,
} from '@codegen/cmsUtils';
import { Language } from '@codegen/gatewayUtils';
import { dohopConnectQueryPlaceholderData } from '@utils/queryUtils';
import { mapHostNameToPartner } from '@web/utils/hostnameUtils';

// We try to get the icon through the partners iconConfig, if the partner doesn't have one we use the dohop one
const useGetIcon = () => {
  const partner = mapHostNameToPartner(
    typeof window !== 'undefined' ? window.location.hostname : undefined,
  );

  const { data: partnerData } = useGetPartnerQuery(
    {
      partner,
    },
    { retry: 2 },
  );

  const { locale } = useRouter();

  const { data: dohopData } = useDohopConnectQuery(
    { locale: locale as Language },
    {
      keepPreviousData: true,
      placeholderData: dohopConnectQueryPlaceholderData,
    },
  );

  const iconConfig = partnerData?.partner?.iconConfig;
  const dohopIconConfig = dohopData?.dohopConnect?.iconConfig;

  const getIcon = (iconIdentifier: keyof IconConfigFragment) => {
    if (
      (!iconConfig && !dohopIconConfig) ||
      (!iconConfig?.[iconIdentifier] && !dohopIconConfig?.[iconIdentifier])
    ) {
      return null;
    }

    return (iconConfig?.[iconIdentifier] ||
      dohopIconConfig?.[iconIdentifier]) as ImageWithConfigFragment;
  };

  return getIcon;
};

export default useGetIcon;
